import React from 'react';
import Layout from '../components/layout'
import {graphql} from 'gatsby';

import {Card} from 'components/collaboration/card'
import {Banner} from 'components/crops'

import {Content} from 'components/collaboration/content'

const CollaborationTemplate = (props) => (
  
  <Layout
    articleId={`${props.data.page.type}-${props.data.page.wordpress_id}`}
    context = {props.pageContext}
    location = {props.location}
    ancestors = {props.data.page.ancestors}
  >
    <header className="entry-header md:px-0 px-5">
      <Banner title={props.data.page.title} image={props.data.page.featured_media}/>
    </header>
    <div className="entry container px-5 mt-10" >
      <div className="entry-content">
        <Content {...props.data.page}/>
      </div>
    </div>
  </Layout>
)


export default CollaborationTemplate

export const SingleCollabQuery = graphql `
query SingleCollabById($id: String!){
  page:wordpressWpCollaboration(id:{eq:$id}){
    title
    content
    path
    type
    date
    ancestors {
      link
      label
    }
    tags {
      id
      name
    }
    images {
          localFile{
            ...GalleryThumbnail
          }
      }
    
    wordpress_id
    featured_media {
      localFile {
        ...PageBannerImages
      }
    }
  }
}
`
